import {
  ContentfulLegalRequirementQuery,
  ContentfulLegalRequirementWithCorporateQuery,
  ContentfulLegalRequirementWithCorporateAndProvinceQuery,
} from '../../__generated__/graphql-types';
import {
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from 'gatsby-source-contentful/rich-text';
import { EnUs, Language } from '../i18n';
import { TFunction } from 'i18next';
import { Err, JsonDecoder, Ok, Result } from 'ts.data.json';
import { slugify, isString } from '../string-utils';
import {
  RichTextDecoder,
  combineResults,
  CommonModelDecoder,
  decodeContentfulModel,
  contentfulEntryUrl,
  TrimmedString,
} from './common';
import { enableStrictDecoderErrors } from '../env';

export type LegalRequirement = {
  contentful_id: string;
  node_locale: Language;
  country: string;
  requirement: RenderRichTextData<ContentfulRichTextGatsbyReference>;

  // Base url is the same across different node locales (based on English version of translated content).
  baseUrl: string;
};

export type LegalRequirementWithCorporate = LegalRequirement & {
  corporateStructure: CorporateStructure;
};

export type LegalRequirementWithProvinceAndCorporate = LegalRequirement & {
  province: string;
  corporateStructure: CorporateStructure;
};

export function isLegalRequirementWithCorporate(
  lr: unknown
): lr is LegalRequirementWithCorporate {
  return (
    (lr as LegalRequirementWithCorporate).corporateStructure !== undefined &&
    (lr as LegalRequirementWithProvinceAndCorporate).province === undefined
  );
}

export function isLegalRequirementWithProvinceAndCorporate(
  lr: unknown
): lr is LegalRequirementWithProvinceAndCorporate {
  return (
    (lr as LegalRequirementWithProvinceAndCorporate).province !== undefined &&
    (lr as LegalRequirementWithProvinceAndCorporate).corporateStructure !==
      undefined
  );
}

/**
 * Contentful fields with restricted values cannot have contentful translations.
 * Validation breaks in other languages as the values do not match any accepted strings.
 */
export enum CorporateStructure {
  Ag = 'ag',
  Altro = 'altro',
  BenefitCorporation = 'benefit-corporation',
  BenefitLlc = 'benefit-llc',
  Brand = 'brand',
  Bv = 'bv',
  CoKG = 'co-kg',
  Cooperative = 'co-operative',
  CommunityInterestCompanyLimitedByGuarantee = 'community-interest-company-limited-by-guarantee',
  CommunityInterestCompanyLimitedByShares = 'community-interest-company-limited-by-shares',
  CompanyLimitedByGuarantee = 'company-limited-by-guarantee',
  CompanyLimitedByShares = 'company-limited-by-shares',
  Coop = 'coop',
  CooperativeSociale = 'cooperativa-sociale',
  Corpooration = 'corporation',
  CreditUnion = 'credit-union',
  DesignatedActivityCompany = 'designated-activity-company',
  DesignatedActivityCompanyLimitedByGuarantee = 'designated-activity-company-limited-by-guarantee',
  Eireli = 'eireli',
  Eirl = 'eirl',
  Esop = 'esop',
  FiscallySponsoredOther = 'fiscally-sponsored-other',
  FiscallySponsoredSgf = 'fiscally-sponsored-sgf',
  FlexiblePurposeCorporation = 'flexible-purpose-corporation',
  Gmbh = 'gmbh',
  GmbhCoKG = 'gmbh-co-kg',
  ImpresaSociale = 'impresa-sociale',
  L3c = 'l3c',
  Lda = 'lda',
  LimitedLiabilityCompany = 'limited-liability-company',
  LimitedLiabilityPartnership = 'limited-liability-partnership',
  Ltd = 'ltd',
  Ltda = 'ltda',
  NonProfit = 'non-profit',
  Nv = 'nv',
  Other = 'other',
  Partnership = 'partnership',
  PublicLimitedCompany = 'public-limited-company',
  PrivateCompanyLimitedBySharesWithNoObjectsClause = 'private-company-limited-by-shares-with-no-objects-clause',
  ProfessionalCorporation = 'professional-corporation',
  Pty = 'pty',
  PtyLtd = 'pty-ltd',
  Sencoscs = 's-en-co-s-c-s',
  Scivil = 's-civil',
  Sa = 's-a',
  Sagl = 'sagl-societa-a-garanzia-limitata',
  Sal = 's-a-l',
  Sarl = 'sarl',
  Sas = 'sas',
  Sca = 'sca',
  Sl = 's-l',
  Sll = 's-l-l',
  Slne = 's-l-n-e',
  Slp = 's-l-p',
  SocialPurposeCorporation = 'social-purpose-corporation',
  SocietaAResponsibilitaLimitataSrl = 'societa-a-responsabilita-limitata-srl',
  SocietaAResponsibilitaLimitataSocietaBenefitSrlSb = 'societa-a-responsabilita-limitata-societa-benefit-srl-sb',
  SocietaConsortile = 'societa-consortile',
  SocietaCooperativa = 'societa-cooperativa',
  SocietasEuropaea = 'societas-europaea',
  SocietaInAccomanditaPerAzioniSapa = 'societa-in-accomandita-per-azioni-sapa',
  SocietaInAccomanditaPerAzioniSocietaBenefitSapaSb = 'societa-in-accomandita-per-azioni-societa-benefit-sapa-sb',
  SocietaInAccomanditaSempliceSas = 'societa-in-accomandita-semplice-sas',
  SocietaInAccomanditaSempliceSocietaBenefitSasSb = 'societa-in-accomandita-semplice-societa-benefit-sas-sb',
  SocietaInNomeCollettivoSnc = 'societa-in-nome-collettivo-snc',
  SocietaInNomeColletivoSocietaBenefitSncSb = 'societa-in-nome-collettivo-societa-benefit-snc-sb',
  SocietaPerAzioniSpa = 'societa-per-azioni-spa',
  SocietaPerAzioniSocietaBenefitSpaSb = 'societa-per-azioni-societa-benefit-spa-sb',
  SocietaSempliceSs = 'societa-semplice-ss',
  SocietaSempliceSocietaBenefitSsBb = 'societa-semplice-societa-benefit-ss-bb',
  SocietaTraProfessionisti = 'societa-tra-professionisti',
  SoleProprietorship = 'sole-proprietorship',
  Spa = 'spa',
  SpolkaAkcyjna = 'spolka-akcyjna',
  Spzoo = 'spolka-z-ograniczona-odpowiedzialnoscia',
  Srl = 'srl',
  SucursalesDeSociedadesConstituidasEnElExtranjero = 'sucursales-de-sociedades-constituidas-en-el-extranjero',
  Subsidiary = 'subsidiary',
  UnlimitedCompany = 'unlimited-company',
  Ua = 'ua',
}
export function corporateStructureTranslation(
  t: TFunction,
  cs: CorporateStructure
): string {
  switch (cs) {
    case CorporateStructure.Ag:
      return t('corporate-structure-ag');
    case CorporateStructure.Altro:
      return t('corporate-structure-altro');
    case CorporateStructure.BenefitCorporation:
      return t('corporate-structure-benefit-corporation');
    case CorporateStructure.BenefitLlc:
      return t('corporate-structure-benefit-llc');
    case CorporateStructure.Brand:
      return t('corporate-structure-brand');
    case CorporateStructure.Bv:
      return t('corporate-structure-bv');
    case CorporateStructure.CoKG:
      return t('corporate-structure-co-kg');
    case CorporateStructure.Cooperative:
      return t('corporate-structure-co-operative');
    case CorporateStructure.CommunityInterestCompanyLimitedByGuarantee:
      return t(
        'corporate-structure-community-interest-company-limited-by-guarantee'
      );
    case CorporateStructure.CommunityInterestCompanyLimitedByShares:
      return t(
        'corporate-structure-community-interest-company-limited-by-shares'
      );
    case CorporateStructure.CompanyLimitedByGuarantee:
      return t('corporate-structure-company-limited-by-guarantee');
    case CorporateStructure.CompanyLimitedByShares:
      return t('corporate-structure-company-limited-by-shares');
    case CorporateStructure.Coop:
      return t('corporate-structure-coop');
    case CorporateStructure.CooperativeSociale:
      return t('corporate-structure-cooperativa-sociale');
    case CorporateStructure.Corpooration:
      return t('corporate-structure-corporation');
    case CorporateStructure.CreditUnion:
      return t('corporate-structure-credit-union');
    case CorporateStructure.DesignatedActivityCompany:
      return t('corporate-structure-designated-activity-company');
    case CorporateStructure.DesignatedActivityCompanyLimitedByGuarantee:
      return t(
        'corporate-structure-designated-activity-company-limited-by-guarantee'
      );
    case CorporateStructure.Eireli:
      return t('corporate-structure-eireli');
    case CorporateStructure.Eirl:
      return t('corporate-structure-eirl');
    case CorporateStructure.Esop:
      return t('corporate-structure-esop');
    case CorporateStructure.FiscallySponsoredOther:
      return t('corporate-structure-fiscally-sponsored-other');
    case CorporateStructure.FiscallySponsoredSgf:
      return t('corporate-structure-fiscally-sponsored-sgf');
    case CorporateStructure.FlexiblePurposeCorporation:
      return t('corporate-structure-flexible-purpose-corporation');
    case CorporateStructure.Gmbh:
      return t('corporate-structure-gmbh');
    case CorporateStructure.GmbhCoKG:
      return t('corporate-structure-gmbh-co-kg');
    case CorporateStructure.ImpresaSociale:
      return t('corporate-structure-impresa-sociale');
    case CorporateStructure.L3c:
      return t('corporate-structure-l3c');
    case CorporateStructure.Lda:
      return t('corporate-structure-lda');
    case CorporateStructure.LimitedLiabilityCompany:
      return t('corporate-structure-limited-liability-company');
    case CorporateStructure.LimitedLiabilityPartnership:
      return t('corporate-structure-limited-liability-partnership');
    case CorporateStructure.Ltd:
      return t('corporate-structure-ltd');
    case CorporateStructure.Ltda:
      return t('corporate-structure-ltda');
    case CorporateStructure.NonProfit:
      return t('corporate-structure-non-profit');
    case CorporateStructure.Nv:
      return t('corporate-structure-nv');
    case CorporateStructure.Other:
      return t('corporate-structure-other');
    case CorporateStructure.Partnership:
      return t('corporate-structure-partnership');
    case CorporateStructure.PrivateCompanyLimitedBySharesWithNoObjectsClause:
      return t(
        'corporate-structure-private-company-limited-by-shares-with-no-objects-clause'
      );
    case CorporateStructure.PublicLimitedCompany:
      return t('corporate-structure-public-limited-company');
    case CorporateStructure.ProfessionalCorporation:
      return t('corporate-structure-professional-corporation');
    case CorporateStructure.Pty:
      return t('corporate-structure-pty');
    case CorporateStructure.PtyLtd:
      return t('corporate-structure-pty-ltd');
    case CorporateStructure.Sencoscs:
      return t('corporate-structure-s-en-co-s-c-s');
    case CorporateStructure.Scivil:
      return t('corporate-structure-s-civil');
    case CorporateStructure.Sa:
      return t('corporate-structure-s-a');
    case CorporateStructure.Sagl:
      return t('corporate-structure-sagl-societa-a-garanzia-limitata');
    case CorporateStructure.Sal:
      return t('corporate-structure-s-a-l');
    case CorporateStructure.Sl:
      return t('corporate-structure-s-l');
    case CorporateStructure.Sll:
      return t('corporate-structure-s-l-l');
    case CorporateStructure.Slne:
      return t('corporate-structure-s-l-n-e');
    case CorporateStructure.Slp:
      return t('corporate-structure-s-l-p');
    case CorporateStructure.Sarl:
      return t('corporate-structure-sarl');
    case CorporateStructure.Sas:
      return t('corporate-structure-sas');
    case CorporateStructure.Sca:
      return t('corporate-structure-sca');
    case CorporateStructure.SocialPurposeCorporation:
      return t('corporate-structure-social-purpose-corporation');
    case CorporateStructure.SocietaAResponsibilitaLimitataSrl:
      return t('corporate-structure-societa-a-responsabilita-limitata-srl');
    case CorporateStructure.SocietaAResponsibilitaLimitataSocietaBenefitSrlSb:
      return t(
        'corporate-structure-societa-a-responsabilita-limitata-societa-benefit-srl-sb'
      );
    case CorporateStructure.SocietaConsortile:
      return t('corporate-structure-societa-consortile');
    case CorporateStructure.SocietaCooperativa:
      return t('corporate-structure-societa-cooperativa');
    case CorporateStructure.SocietaInAccomanditaPerAzioniSapa:
      return t('corporate-structure-societa-in-accomandita-per-azioni-sapa');
    case CorporateStructure.SocietaInAccomanditaPerAzioniSocietaBenefitSapaSb:
      return t(
        'corporate-structure-societa-in-accomandita-per-azioni-societa-benefit-sapa-sb'
      );
    case CorporateStructure.SocietaInAccomanditaSempliceSas:
      return t('corporate-structure-societa-in-accomandita-semplice-sas');
    case CorporateStructure.SocietaInAccomanditaSempliceSocietaBenefitSasSb:
      return t(
        'corporate-structure-societa-in-accomandita-semplice-societa-benefit-sas-sb'
      );
    case CorporateStructure.SocietaInNomeCollettivoSnc:
      return t('corporate-structure-societa-in-nome-collettivo-snc');
    case CorporateStructure.SocietaInNomeColletivoSocietaBenefitSncSb:
      return t(
        'corporate-structure-societa-in-nome-collettivo-societa-benefit-snc-sb'
      );
    case CorporateStructure.SocietaPerAzioniSpa:
      return t('corporate-structure-societa-per-azioni-spa');
    case CorporateStructure.SocietaPerAzioniSocietaBenefitSpaSb:
      return t('corporate-structure-societa-per-azioni-societa-benefit-spa-sb');
    case CorporateStructure.SocietaSempliceSs:
      return t('corporate-structure-societa-semplice-ss');
    case CorporateStructure.SocietaSempliceSocietaBenefitSsBb:
      return t('corporate-structure-societa-semplice-societa-benefit-ss-bb');
    case CorporateStructure.SocietaTraProfessionisti:
      return t('corporate-structure-societa-tra-professionisti');
    case CorporateStructure.SocietasEuropaea:
      return t('corporate-structure-societas-europaea');
    case CorporateStructure.SoleProprietorship:
      return t('corporate-structure-sole-proprietorship');
    case CorporateStructure.Spa:
      return t('corporate-structure-spa');
    case CorporateStructure.SpolkaAkcyjna:
      return t('corporate-structure-spolka-akcyjna');
    case CorporateStructure.Spzoo:
      return t('corporate-structure-spzoo');
    case CorporateStructure.Subsidiary:
      return t('corporate-structure-subsidiary');
    case CorporateStructure.SucursalesDeSociedadesConstituidasEnElExtranjero:
      return t(
        'corporate-structure-sucursales-de-sociedades-constituidas-en-el-extranjero'
      );
    case CorporateStructure.Srl:
      return t('corporate-structure-srl');
    case CorporateStructure.UnlimitedCompany:
      return t('corporate-structure-unlimited-company');
    case CorporateStructure.Ua:
      return t('corporate-structure-ua');
  }
}

const CorporateStructureDecoder = JsonDecoder.array(
  JsonDecoder.string.chain(
    (cs: string): JsonDecoder.Decoder<CorporateStructure> => {
      switch (cs) {
        case 'ag':
          return JsonDecoder.constant(CorporateStructure.Ag);
        case 'altro':
          return JsonDecoder.constant(CorporateStructure.Altro);
        case 'benefit-corporation':
          return JsonDecoder.constant(CorporateStructure.BenefitCorporation);
        case 'benefit-llc':
          return JsonDecoder.constant(CorporateStructure.BenefitLlc);
        case 'brand':
          return JsonDecoder.constant(CorporateStructure.Brand);
        case 'bv':
          return JsonDecoder.constant(CorporateStructure.Bv);
        case 'co-kg':
          return JsonDecoder.constant(CorporateStructure.CoKG);
        case 'co-operative':
          return JsonDecoder.constant(CorporateStructure.Cooperative);
        case 'community-interest-company-limited-by-guarantee':
          return JsonDecoder.constant(
            CorporateStructure.CommunityInterestCompanyLimitedByGuarantee
          );
        case 'community-interest-company-limited-by-shares':
          return JsonDecoder.constant(
            CorporateStructure.CommunityInterestCompanyLimitedByShares
          );
        case 'company-limited-by-guarantee':
          return JsonDecoder.constant(
            CorporateStructure.CompanyLimitedByGuarantee
          );
        case 'company-limited-by-shares':
          return JsonDecoder.constant(
            CorporateStructure.CompanyLimitedByShares
          );
        case 'coop':
          return JsonDecoder.constant(CorporateStructure.Coop);
        case 'cooperativa-sociale':
          return JsonDecoder.constant(CorporateStructure.CooperativeSociale);
        case 'corporation':
          return JsonDecoder.constant(CorporateStructure.Corpooration);
        case 'credit-union':
          return JsonDecoder.constant(CorporateStructure.CreditUnion);
        case 'designated-activity-company':
          return JsonDecoder.constant(
            CorporateStructure.DesignatedActivityCompany
          );
        case 'designated-activity-company-limited-by-guarantee':
          return JsonDecoder.constant(
            CorporateStructure.DesignatedActivityCompanyLimitedByGuarantee
          );
        case 'eireli':
          return JsonDecoder.constant(CorporateStructure.Eireli);
        case 'eirl':
          return JsonDecoder.constant(CorporateStructure.Eirl);
        case 'esop':
          return JsonDecoder.constant(CorporateStructure.Esop);
        case 'fiscally-sponsored-other':
          return JsonDecoder.constant(
            CorporateStructure.FiscallySponsoredOther
          );
        case 'fiscally-sponsored-sgf':
          return JsonDecoder.constant(CorporateStructure.FiscallySponsoredSgf);
        case 'flexible-purpose-corporation':
          return JsonDecoder.constant(
            CorporateStructure.FlexiblePurposeCorporation
          );
        case 'gmbh':
          return JsonDecoder.constant(CorporateStructure.Gmbh);
        case 'gmbh-co-kg':
          return JsonDecoder.constant(CorporateStructure.GmbhCoKG);
        case 'impresa-sociale':
          return JsonDecoder.constant(CorporateStructure.ImpresaSociale);
        case 'l3c':
          return JsonDecoder.constant(CorporateStructure.L3c);
        case 'lda':
          return JsonDecoder.constant(CorporateStructure.Lda);
        case 'limited-liability-company':
          return JsonDecoder.constant(
            CorporateStructure.LimitedLiabilityCompany
          );
        case 'limited-liability-partnership':
          return JsonDecoder.constant(
            CorporateStructure.LimitedLiabilityPartnership
          );
        case 'ltd':
          return JsonDecoder.constant(CorporateStructure.Ltd);
        case 'ltda':
          return JsonDecoder.constant(CorporateStructure.Ltda);
        case 'non-profit':
          return JsonDecoder.constant(CorporateStructure.NonProfit);
        case 'nv':
          return JsonDecoder.constant(CorporateStructure.Nv);
        case 'other':
          return JsonDecoder.constant(CorporateStructure.Other);
        case 'partnership':
          return JsonDecoder.constant(CorporateStructure.Partnership);
        case 'public-limited-company':
          return JsonDecoder.constant(CorporateStructure.PublicLimitedCompany);
        case 'private-company-limited-by-shares-with-no-objects-clause':
          return JsonDecoder.constant(
            CorporateStructure.PrivateCompanyLimitedBySharesWithNoObjectsClause
          );
        case 'professional-corporation':
          return JsonDecoder.constant(
            CorporateStructure.ProfessionalCorporation
          );
        case 'pty':
          return JsonDecoder.constant(CorporateStructure.Pty);
        case 'pty-ltd':
          return JsonDecoder.constant(CorporateStructure.PtyLtd);
        case 's-en-co-s-c-s':
          return JsonDecoder.constant(CorporateStructure.Sencoscs);
        case 's-civil':
          return JsonDecoder.constant(CorporateStructure.Scivil);
        case 's-a':
          return JsonDecoder.constant(CorporateStructure.Sa);
        case 'sagl-societa-a-garanzia-limitata':
          return JsonDecoder.constant(CorporateStructure.Sagl);
        case 's-a-l':
          return JsonDecoder.constant(CorporateStructure.Sal);
        case 's-l':
          return JsonDecoder.constant(CorporateStructure.Sl);
        case 's-l-l':
          return JsonDecoder.constant(CorporateStructure.Sll);
        case 's-l-n-e':
          return JsonDecoder.constant(CorporateStructure.Slne);
        case 's-l-p':
          return JsonDecoder.constant(CorporateStructure.Slp);
        case 'sarl':
          return JsonDecoder.constant(CorporateStructure.Sarl);
        case 'sas':
          return JsonDecoder.constant(CorporateStructure.Sas);
        case 'sca':
          return JsonDecoder.constant(CorporateStructure.Sca);
        case 'social-purpose-corporation':
          return JsonDecoder.constant(
            CorporateStructure.SocialPurposeCorporation
          );
        case 'societa-a-responsabilita-limitata-srl':
          return JsonDecoder.constant(
            CorporateStructure.SocietaAResponsibilitaLimitataSrl
          );
        case 'societa-a-responsabilita-limitata-societa-benefit-srl-sb':
          return JsonDecoder.constant(
            CorporateStructure.SocietaAResponsibilitaLimitataSocietaBenefitSrlSb
          );
        case 'societa-consortile':
          return JsonDecoder.constant(CorporateStructure.SocietaConsortile);
        case 'societa-cooperativa':
          return JsonDecoder.constant(CorporateStructure.SocietaCooperativa);
        case 'societa-in-accomandita-per-azioni-sapa':
          return JsonDecoder.constant(
            CorporateStructure.SocietaInAccomanditaPerAzioniSapa
          );
        case 'societa-in-accomandita-per-azioni-societa-benefit-sapa-sb':
          return JsonDecoder.constant(
            CorporateStructure.SocietaInAccomanditaPerAzioniSocietaBenefitSapaSb
          );
        case 'societa-in-accomandita-semplice-sas':
          return JsonDecoder.constant(
            CorporateStructure.SocietaInAccomanditaSempliceSas
          );
        case 'societa-in-accomandita-semplice-societa-benefit-sas-sb':
          return JsonDecoder.constant(
            CorporateStructure.SocietaSempliceSocietaBenefitSsBb
          );
        case 'societa-in-nome-collettivo-snc':
          return JsonDecoder.constant(
            CorporateStructure.SocietaInNomeCollettivoSnc
          );
        case 'societa-in-nome-collettivo-societa-benefit-snc-sb':
          return JsonDecoder.constant(
            CorporateStructure.SocietaInNomeColletivoSocietaBenefitSncSb
          );
        case 'societa-per-azioni-spa':
          return JsonDecoder.constant(CorporateStructure.SocietaPerAzioniSpa);
        case 'societa-per-azioni-societa-benefit-spa-sb':
          return JsonDecoder.constant(
            CorporateStructure.SocietaPerAzioniSocietaBenefitSpaSb
          );
        case 'societa-semplice-ss':
          return JsonDecoder.constant(CorporateStructure.SocietaSempliceSs);
        case 'societa-semplice-societa-benefit-ss-bb':
          return JsonDecoder.constant(
            CorporateStructure.SocietaSempliceSocietaBenefitSsBb
          );
        case 'societa-tra-professionisti':
          return JsonDecoder.constant(
            CorporateStructure.SocietaTraProfessionisti
          );
        case 'societas-europaea':
          return JsonDecoder.constant(CorporateStructure.SocietasEuropaea);
        case 'sole-proprietorship':
          return JsonDecoder.constant(CorporateStructure.SoleProprietorship);
        case 'spa':
          return JsonDecoder.constant(CorporateStructure.Spa);
        case 'spolka-akcyjna':
          return JsonDecoder.constant(CorporateStructure.SpolkaAkcyjna);
        case 'spolka-z-ograniczona-odpowiedzialnoscia':
          return JsonDecoder.constant(CorporateStructure.Spzoo);
        case 'subsidiary':
          return JsonDecoder.constant(CorporateStructure.Subsidiary);
        case 'sucursales-de-sociedades-constituidas-en-el-extranjero':
          return JsonDecoder.constant(
            CorporateStructure.SucursalesDeSociedadesConstituidasEnElExtranjero
          );
        case 'srl':
          return JsonDecoder.constant(CorporateStructure.Srl);
        case 'unlimited-company':
          return JsonDecoder.constant(CorporateStructure.UnlimitedCompany);
        case 'ua':
          return JsonDecoder.constant(CorporateStructure.Ua);
      }

      return JsonDecoder.fail(`unknown corporate structure ${cs}`);
    }
  ),
  'corporateStructures[]'
);

const ContentfulLegalRequirementDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      countries: JsonDecoder.array(TrimmedString, 'countries[]'),
      requirement: RichTextDecoder,
    },
    'LegalRequirement'
  )
);

export function decodeContentfulLegalRequirementQuery(
  data: ContentfulLegalRequirementQuery
): Result<LegalRequirement[]> {
  const results = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'Raw LegalRequirement',
    data.allContentfulLegalRequirement.nodes,
    ContentfulLegalRequirementDecoder
  );

  if (!results.isOk()) {
    return new Err(results.error);
  }

  const getUrl = function (
    contentfulID: string,
    countryID: number,
    countries: string[]
  ): Result<string> {
    const englishVersion = results.value.find(
      lr => lr.node_locale === EnUs && lr.contentful_id === contentfulID
    );

    if (!englishVersion) {
      return new Err(`no english version found ${contentfulID}`);
    }

    const country = englishVersion.countries[countryID];
    if (!country) {
      return new Err(
        `missing english version of country ${contentfulID} ${englishVersion.countries} ${countryID} ${countries}`
      );
    }

    return new Ok(`/legal-requirement/country/${slugify(country)}`);
  };

  const result: Result<LegalRequirement>[] = results.value.flatMap(lr => {
    const { countries, ...filteredProps } = lr;
    return countries.map((country, countryIndex) => {
      const urlResult = getUrl(lr.contentful_id, countryIndex, countries);

      if (!urlResult.isOk()) {
        return new Err(urlResult.error);
      }

      const requirement: LegalRequirement = {
        ...filteredProps,
        country: country,
        baseUrl: urlResult.value,
      };

      return new Ok(requirement);
    });
  });

  return combineResults(result);
}

const ContentfulLegalRequirementWithCorporateDecoder = JsonDecoder.combine(
  CommonModelDecoder,
  JsonDecoder.object(
    {
      countries: JsonDecoder.array(TrimmedString, 'countries[]'),
      corporateStructures: CorporateStructureDecoder,
      requirement: RichTextDecoder,
    },
    'LegalRequirementWithCorporateStructures'
  )
);

export function decodeContentfulLegalRequirementWithCorporateQuery(
  data: ContentfulLegalRequirementWithCorporateQuery
): Result<LegalRequirementWithCorporate[]> {
  const results = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'Raw LegalRequirementWithCorporateStructures',
    data.allContentfulLegalRequirementWithCorporate.nodes,
    ContentfulLegalRequirementWithCorporateDecoder
  );

  if (!results.isOk()) {
    return new Err(results.error);
  }

  const getUrl = function (
    contentfulID: string,
    countryID: number,
    corporateStructure: string
  ): Result<string> {
    const englishVersion = results.value.find(
      lr => lr.node_locale === EnUs && lr.contentful_id === contentfulID
    );

    if (!englishVersion) {
      return new Err(`no english version found ${contentfulID}`);
    }

    const country = englishVersion.countries[countryID];
    if (!country) {
      return new Err(`missing english version of country ${contentfulID}`);
    }

    return new Ok(
      `/legal-requirement/country/${slugify(
        country
      )}/corporate-structure/${slugify(corporateStructure)}`
    );
  };

  const result: Result<LegalRequirementWithCorporate>[] = results.value.flatMap(
    lr => {
      const { countries, corporateStructures, ...filteredProps } = lr;
      return countries.flatMap((country, countryIndex) => {
        return corporateStructures.map(corporateStructure => {
          const urlResult = getUrl(
            lr.contentful_id,
            countryIndex,
            corporateStructure
          );

          if (!urlResult.isOk()) {
            return new Err(urlResult.error);
          }

          const requirement: LegalRequirementWithCorporate = {
            ...filteredProps,
            country: country,
            corporateStructure: corporateStructure,
            baseUrl: urlResult.value,
          };

          return new Ok(requirement);
        });
      });
    }
  );

  return combineResults(result);
}

const ContentfulLegalRequirementWithCorporateAndProvinceDecoder =
  JsonDecoder.combine(
    CommonModelDecoder,
    JsonDecoder.object(
      {
        country: TrimmedString,
        provinces: JsonDecoder.array(TrimmedString, 'provinces[]'),
        corporateStructures: CorporateStructureDecoder,
        requirement: RichTextDecoder,
      },
      'LegalRequirementWithProvinceAndCorporateStructures'
    )
  );

export function decodeContentfulLegalRequirementWithProvinceAndCorpoateQuery(
  data: ContentfulLegalRequirementWithCorporateAndProvinceQuery
): Result<LegalRequirementWithProvinceAndCorporate[]> {
  const results = decodeContentfulModel(
    enableStrictDecoderErrors(),
    'Raw LegalRequirementWithProvinceAndCorporateStructures',
    data.allContentfulLegalRequirementWithCorporateAndProvince.nodes,
    ContentfulLegalRequirementWithCorporateAndProvinceDecoder
  );

  if (!results.isOk()) {
    return new Err(results.error);
  }

  const getUrl = function (
    contentfulID: string,
    provinceID: number,
    corporateStructure: string
  ): Result<string> {
    const englishVersion = results.value.find(
      lr => lr.node_locale === EnUs && lr.contentful_id === contentfulID
    );

    if (!englishVersion) {
      return new Err(`no english version found ${contentfulID}`);
    }

    const province = englishVersion.provinces[provinceID];
    if (!province) {
      return new Err(`missing english version of province ${contentfulID}`);
    }

    return new Ok(
      `/legal-requirement/country/${slugify(
        englishVersion.country
      )}/province/${slugify(province)}/corporate-structure/${slugify(
        corporateStructure
      )}`
    );
  };

  const result: Result<LegalRequirementWithProvinceAndCorporate>[] =
    results.value.flatMap(lr => {
      const { provinces, corporateStructures, ...filteredProps } = lr;
      return provinces.flatMap((province, provinceIndex) => {
        return corporateStructures.map(corporateStructure => {
          const urlResult = getUrl(
            lr.contentful_id,
            provinceIndex,
            corporateStructure
          );

          if (!urlResult.isOk()) {
            return new Err(urlResult.error);
          }

          const requirement: LegalRequirementWithProvinceAndCorporate = {
            ...filteredProps,
            province: province,
            corporateStructure: corporateStructure,
            baseUrl: urlResult.value,
          };

          return new Ok(requirement);
        });
      });
    });

  return combineResults(result);
}

type ProvincerequirementMap = {
  provinces: { [province: string]: { [structure: string]: RequirementLink } };
};

type CorporateStructureRequirementMap = {
  corporateStructures: { [structure: string]: RequirementLink };
};

export function isCorporateStructureRequirementMap(
  a: unknown
): a is CorporateStructureRequirementMap {
  return (
    (a as CorporateStructureRequirementMap).corporateStructures !== undefined
  );
}

export type RequirementLink = string;
export type LegalRequirementMap = {
  [country: string]:
    | RequirementLink
    | CorporateStructureRequirementMap
    | ProvincerequirementMap;
};

export function decodeRequirementMap(
  legalRequirements: Array<LegalRequirement>,
  legalRequirementsWithCorporate: Array<LegalRequirementWithCorporate>,
  legalRequirementsWithCorporateAndProvince: Array<LegalRequirementWithProvinceAndCorporate>
): Result<LegalRequirementMap> {
  const result: LegalRequirementMap = {};
  const errors: string[] = [];

  legalRequirements.forEach(lr => {
    result[lr.country] = `/${lr.node_locale}${lr.baseUrl}`;
  });

  legalRequirementsWithCorporate.forEach(lr => {
    const url = `/${lr.node_locale}${lr.baseUrl}`;
    const countryMatch = result[lr.country] || {
      corporateStructures: { [lr.corporateStructure]: url },
    };

    if (isString(countryMatch)) {
      errors.push(
        [
          `found conflicting 'Simple Legal Requirement' and 'Legal Requirement with Corporate' entries for ${lr.country}`,
          contentfulEntryUrl(lr.contentful_id),
        ].join('\n')
      );
      return;
    }

    if (!isCorporateStructureRequirementMap(countryMatch)) {
      errors.push(
        [
          `found conflicting 'Legal Requirement with Province + Corporate' and 'Legal Requirement with Corporate' entries for ${lr.country}`,
          contentfulEntryUrl(lr.contentful_id),
        ].join('\n')
      );
      return;
    }

    countryMatch.corporateStructures[lr.corporateStructure] = url;
    result[lr.country] = countryMatch;
  });

  legalRequirementsWithCorporateAndProvince.forEach(lr => {
    const countryMatch = result[lr.country] || {
      provinces: { [lr.province]: {} },
    };

    if (isString(countryMatch)) {
      errors.push(
        [
          `found conflicting 'Simple Legal Requirement' and 'Legal Requirement with Province + Corporate' entries for ${lr.country}`,
          contentfulEntryUrl(lr.contentful_id),
        ].join('\n')
      );
      return;
    }

    if (isCorporateStructureRequirementMap(countryMatch)) {
      errors.push(
        [
          `found conflicting 'Legal Requirement with Corporate' and 'Legal Requirement with Province + Corporate' entries for ${lr.country}`,
          contentfulEntryUrl(lr.contentful_id),
        ].join('\n')
      );
      return;
    }

    const provinceMatch = countryMatch.provinces[lr.province] || {
      [lr.corporateStructure]: '',
    };

    provinceMatch[lr.corporateStructure] = `/${lr.node_locale}${lr.baseUrl}`;
    countryMatch.provinces[lr.province] = provinceMatch;
    result[lr.country] = countryMatch;
  });

  if (enableStrictDecoderErrors() && errors.length !== 0) {
    return new Err(errors.join('\n'));
  }

  errors.forEach(e => console.error(e));
  return new Ok(result);
}
